<template>
  <div>
    <h1>...</h1>
  </div>
</template>

<script>
import { LogoutReasons } from '@/constants/user'

export default {
  name: 'Logout',
  created () {
    this.$store.dispatch('logOut')
    const reason = this.$route.query.reason
    if (reason) {
      const translate = LogoutReasons[reason]
      this.$toast.info(translate || this.$route.query.reason, {
        dismissible: true,
        duration: 0
      })
    }
    this.$router.push('/login')
  }
}
</script>
